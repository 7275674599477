<template>
  <div>
    <div class="af-card">
      <p class="is-size-4 has-text-weight-bold">
        <span v-if="action === 'registration'">{{
          $t("af:welcome.registration.title")
        }}</span>
        <span v-else>{{ $t("af:welcome.login.title") }}</span>
      </p>

      <template v-if="isBrand(CUSTOM_APPEARANCE.MBH)">
        <b-button
          id="WelcomeButtonEmailLogin"
          class="mb-4"
          @click="navigateEmail"
          icon-left="envelope"
        >
          {{ $t("af:welcome.button.email_login") }}
        </b-button>
        <b-button
          id="WelcomeButtonPhoneLogin"
          @click="navigatePhone"
          icon-left="phone-alt"
        >
          {{ $t("af:welcome.button.phone_login") }}
        </b-button>
      </template>
      <template v-else>
        <button
          id="WelcomeButtonEmailLogin"
          class="af-btn mb-4"
          @click="navigateEmail"
        >
          {{ $t("af:welcome.button.email_login") }}
          <b-icon
            icon-pack="fa"
            icon="envelope"
            type="is-blue"
            size="is-medium"
          ></b-icon>
        </button>
        <button
          id="WelcomeButtonPhoneLogin"
          class="af-btn"
          @click="navigatePhone"
        >
          {{ $t("af:welcome.button.phone_login") }}
          <b-icon
            icon-pack="fa"
            icon="phone-alt"
            type="is-green"
            size="is-medium"
          ></b-icon>
        </button>
      </template>

      <div class="change-action pt-5">
        <p v-if="action === 'registration'">
          <span>{{ $t("af:welcome.registration.footer.title") }}</span>
          <a @click="navigateToLogin" class="ml-2" id="WelcomeButtonLogin">
            {{ $t("af:welcome.registration.footer.button.to_login") }}
          </a>
        </p>

        <p v-else>
          <span>{{ $t("af:welcome.login.footer.title") }}</span>
          <a
            @click="navigateToRegistration"
            class="ml-2"
            id="WelcomeButtonRegistration"
          >
            {{ $t("af:welcome.login.footer.button.to_registration") }}
          </a>
        </p>
      </div>
    </div>
    <div v-if="!isMortgage" class="af-card mt-5">
      <p class="has-text-left">
        {{ $t("af:welcome.login.prepayment_request.info") }}
      </p>
      <b-button @click="prepaymentFormOpened = true">
        {{ $t("af:welcome.login.prepayment_request.button") }}
      </b-button>
    </div>

    <b-modal v-model="prepaymentFormOpened">
      <div class="af-card" :style='{"height":"initial"}'>
        <PrepaymentForm @prepayment-sent="prepaymentFormOpened = false" />
      </div>
    </b-modal>
  </div>
</template>

<script>
import { isBrand } from "@/utils/util";
import { CUSTOM_APPEARANCE } from "@/utils/const";
import PrepaymentForm from "@/components/prepaymentrequest/PrepaymentForm.vue";

export default {
  name: "Welcome",
  components: { PrepaymentForm },
  computed: {
    CUSTOM_APPEARANCE() {
      return CUSTOM_APPEARANCE;
    },
  },
  title: "af:page.welcome.title",
  data() {
    return {
      action: "", // registration | login
      prepaymentFormOpened: false,
      isMortgage: false,
    };
  },
  watch: {
    "$route.meta.action": {
      immediate: true,
      handler: function () {
        this.action = this.$route.meta.action;
      },
    },
    "$route.meta.mortgage": {
      immediate: true,
      handler: function () {
        this.isMortgage = this.$route.meta.mortgage;
      },
    },
  },
  methods: {
    isBrand,
    navigateToLogin() {
      if (this.$route.meta.mortgage) {
        this.$router.push({
          name: "MortgageWelcomeLogin",
        });
      } else {
        this.$router.push({
          name: "WelcomeLogin",
        });
      }
    },
    navigateToRegistration() {
      if (this.$route.meta.mortgage) {
        this.$router.push({
          name: "MortgageEmailRegistration",
        });
      } else {
        this.$router.push({
          name: "EmailRegistration",
        });
      }
    },
    navigateEmail() {
      if (this.action === "registration") {
        if (this.$route.meta.mortgage) {
          this.$router.push({
            name: "MortgageEmailRegistration",
          });
        } else {
          this.$router.push({
            name: "EmailRegistration",
          });
        }
      } else {
        this.$router.push({
          name: "EmailLogin",
        });
      }
    },
    navigatePhone() {
      if (this.action === "registration") {
        if (this.$route.meta.mortgage) {
          this.$router.push({
            name: "MortgagePhoneRegistration",
          });
        } else {
          this.$router.push({
            name: "PhoneRegistration",
          });
        }
      } else {
        this.$router.push({
          name: "PhoneLogin",
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.af-card {
  background: white;
  border-radius: 16px;
  padding: 20px;
  text-align: center;
  height: 100%;

  display: flex;
  flex-direction: column;
}

.af-btn {
  display: block;
  padding: 15px;
  background: white;
  color: #051c3a;
  border: 1.5px solid #051c3a;
  border-radius: 10px;
  font-weight: 500;
  width: 100%;
  position: relative;
  cursor: pointer;

  span.icon {
    position: absolute;
    left: 25px;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.mbh {
  .is-size-4 {
    font-size: 32px !important;
  }

  .af-card {
    border-radius: 32px;
  }
}

@media only screen and (min-width: 769px) {
  .af-card {
    padding: 32px 36px;
  }

  .change-action {
    margin-top: auto !important;
  }
}
</style>
